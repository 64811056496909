export default defineAppConfig({
  saasName: 'Siroko',
  saasLink: 'https://siroko.fsbd.ai',
  customSaas: true,
  languageStrings: {
    company: 'Departamento',
    companies: 'Departamentos',
    briefing: {
      visibility: 'Si el briefing se pone en "oculto" únicamente lo visualizarán los usuarios admin, y en "visible" todos los usuarios de cualquier departamento.'
    }
  },
  analyticsLooker: '3de22e34-3d54-4392-99da-e429219e2d4c',
  adify: {
    actions: ['Aumentar', 'Reducir', 'Pausar', 'Mantener'],
    platforms: [],
    platformRelation: {
      googleads: 'jZC5I0OOIJIu8hpkAo3Z',
      facebookads: '9vyAOROkEdHZ6U9E3J9Z'
    },
    vertical: true,
    country: true,

  },
  savvywords: {
    customers: [
      {
        name: 'Siroko',
        code: 'siroko-ciclismo',
        savvywordsDataset: 'SAAS',
        googleadsDataset: 'GOOGLE_ADS_CICLISMO',
        searchtermsBucket: 'keywords_analysis_siroko'
      }
    ]
  },
  insights: {
    filterByCompany: false,
    filterByPlatform: false
  },
  meta: {
    adAccounts: [
      {
        name: 'Redes Lesap (116508232151666)',
        act: 116508232151666
      },
      {
        name: 'Siroko Ciclismo (459825444440521)',
        act: 459825444440521
      },
      {
        name: 'Siroko Gym and Training (2403756436306928)',
        act: 2403756436306928
      },
      {
        name: 'Adventure (202313170377775)',
        act: 202313170377775
      },
      {
        name: 'Golf (1510148442369428)',
        act: 1510148442369428
      },
      {
        name: 'Lifestyle (1017360795088843)',
        act: 1017360795088843
      },
      {
        name: 'Snow (1818602854857317)',
        act: 1818602854857317
      }
    ]
  }
})